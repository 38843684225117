@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Myriad Pro";
  src: url("./fonts/MYRIADPRO-REGULAR.OTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --hover: rgb(56, 74, 235);
  --back: #000;
  --black: rgba(0, 0, 0, 00.9);
  --font: #fff;
  --foot: rgba(0, 0, 0, 00.9);
  --text: #000;
  --card: aliceblue;
  --icon: rgb(56, 74, 235);

  --secondary: rgba(107, 114, 128, 0.085);
  --secondaryBtn: #006edc;
  --sideModal: rgb(30, 37, 54);
  --dots: rgb(30, 37, 54);
  --myColor: #006edc;
}

.dark {
  --hover: rgb(56, 74, 235);
  --back: rgba(0, 0, 0, 00.7);
  --black: rgba(0, 0, 0, 00.9);
  --btn: #713f12;
  --font: #fff;
  --foot: rgba(0, 0, 0, 00.9);
  --text: #fff;
  --icon: rgb(56, 74, 235);
  --card: rgba(202, 190, 190, 0.221);
  --secondary: rgba(107, 114, 128, 0.085);
  --secondaryBtn: #006edc;
  --sideModal: rgb(30, 37, 54);
  --dots: rgb(30, 37, 54);
  --myColor: #006edc;
}

.light {
  --card: #111827;
  --hover: rgb(56, 74, 235);
  --back: #ffffffe2;
  --black: #ffffffe2;
  --btn: rgba(202, 190, 190, 0.221);
  --font: #111827;
  --foot: rgba(0, 0, 0, 0.1);
  --text: #000;
  --icon: rgb(241, 246, 247);

  --secondary: #f0f4fb;
  --secondaryBtn: #006edc;
  --sideModal: #f0f4fb;
  --dots: rgba(202, 190, 190, 0.221);
  --myColor: #006edc;
}

input {
  color: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

* {
  font-family: "Myriad Pro", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #9f3144;
  border-radius: 5rem;
}

html,
body,
#root {
  padding-right: 0px !important;
  height: 100%;
  margin: 0;
}
.App {
  display: flex;
  flex-direction: column;
  /* min-height:fit-content; */
}

.content {
  flex: 1;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.nav-link {
  font-weight: bold;
  padding: 1rem;
}

a {
  text-decoration: none;
}

.Background {
  background-image: url(./images/event.svg);
  background-repeat: no-repeat;
  background-size: 50% 50%;
  position: relative;
  bottom: 0;
  right: 10%;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bgEvent {
  background-image: url(./images/eventBg2.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  z-index: -212;
}

@media (max-width: 780px) {
  .bgEvent {
    background-size: 100% 85% !important;
  }
}
@media (min-width: 820px) and (min-height: 1180px) {
  .bgEvent {
    background-size: 100% 55% !important;
    height: 70vh;
  }
}
@media (min-width: 768px) and (min-height: 1024px) {
  .bgEvent {
    background-size: 100% 84% !important;
    height: 50vh;
  }
}

@media (max-width: 500px) {
  .bgEvent {
    background-size: 100% 65% !important;
  }
}
@media (max-width: 480px) {
  .bgEvent {
    background-size: 100% 55% !important;
  }
}
@media (max-width: 380px) {
  .bgEvent {
    background-size: 100% 50% !important;
  }
}
@media (max-width: 370px) {
  .bgEvent {
    background-size: 100% 45% !important;
  }
}

@media (min-width: 820px) and (min-height: 950px) {
  .bgEvent {
    background-size: 100% 70% !important;
    margin-top: 0rem !important;
  }
}
textarea {
  color: black;
}

.swiper-pagination-bullet-active {
  background: #fea4a4 !important;
}
@media (min-width: 768px) and (min-height: 1024px) {
  .tcontainer {
    max-width: 764px !important;
  }
}
@media (max-width: 1000px) {
  .headerMob {
    max-width: 870px !important;
  }
}

